import axios from "axios";

export const baseURL1 = "https://api.opensea.io/api/v2/";

const axiosInstance1 = axios.create({
  baseURL: baseURL1,
});

axiosInstance1.interceptors.request.use(
  (config) => {
    config.headers = {
      "x-api-key": "3cfbcf074af44acfa1a40270e63f26aa",
      ...config.headers,
    };
    return config;
  },
  (err) => Promise.reject(err)
);

export default axiosInstance1;
