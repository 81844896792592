import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import a1 from "../../Images/junglebook11.mp4";
import NftMinting from "../../Components/NftMinting";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import axiosInstance1 from "../../Config1/axios";
import { Dialog } from "@mui/material";
import { useAccount } from "wagmi";
import CreateButoon from "../../Alldilog/ConnectButton";

export default function Home() {
  const [nft, setNft] = useState();
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get("type");
  const [open, setOpen] = React.useState(false);
  const { address } = useAccount();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    localStorage.setItem("type", type);
  }, [type]);

  const getData = () => {
    axiosInstance1
      .get(`collection/${type}/nfts?limit=10`, {})
      .then((res) => {
        setNft(res.data.nfts);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    !address && handleClickOpen();
  }, [address]);
  useEffect(() => {
    address && handleClose();
  }, [address]);
  return (
    <div className="flex flex-col">
      <div className="lg:flex mt-24 lg:px-10 px-3">
        <div className="lg:flex lg:w-[50%]">
          <Carousel
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            autoPlay={true}
          >
            {nft?.map((i) => {
              return (
                <div className="h-[550px]">
                  <img src={i.image_url} className="rounded-lg" alt="" />
                </div>
              );
            })}
          </Carousel>
        </div>
        <div className="flex lg:w-[50%]">
          <NftMinting />
        </div>
      </div>
      <Dialog
        open={open}
        // onClose={handleClose}
        PaperProps={{
          className:
            "!w-[20%] !min-h-[20%]  flex  flex-col justify-center items-center !rounded-2xl !text-shadow-none  !bg-gradient-to-t from-cyan-500 to-blue-100 ",
        }}
      >
        <div className="flex flex-col justify-center items-center border-2 px-5 py-3 border-black rounded-lg text-black">
          <CreateButoon />
        </div>
      </Dialog>
    </div>
  );
}
