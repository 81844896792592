import axios from "axios";

export const baseURL = "https://api.opensea.io/api/v2/";

const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      "x-api-key": "3cfbcf074af44acfa1a40270e63f26aa",
      ...config.headers,
    };
    return config;
  },
  (err) => Promise.reject(err)
);

export default axiosInstance;
