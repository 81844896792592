import { createContext, useContext, useState } from "react";
import { useContractRead, useContractWrite } from "wagmi";
import {
  ExclusiveAbi,
  ExclusiveAddress,
  SanctuaryAbi,
  SanctuaryAddress,
  contractAbi,
  contractAddress,
} from "./constant";
import { toast } from "react-toastify";

export const ContextStaking = createContext();

export const ContectStakingApp = ({ children }) => {
  const [maxSupply, setMaxSupply] = useState();
  const [totalSupply, setTotalSupply] = useState();
  const [maxSupply1, setMaxSupply1] = useState();
  const [totalSupply1, setTotalSupply1] = useState();
  const [value, setValue] = useState(1);
  const [price, setPrice] = useState(0.008);
  const [price1, setPrice1] = useState(0.02);
  const { write: sanctuary } = useContractWrite({
    address: contractAddress,
    abi: contractAbi,
    functionName: "mintPublic",
    // value: `${price * value * 10 ** 18} `, ///use leter
    value: "",
    args: [
      SanctuaryAddress,
      "0x0000a26b00c1F0DF003000390027140000fAa719",
      "0x0000000000000000000000000000000000000000",
      value,
    ],
    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Minting failed ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Minting is Successful ${data.hash.toString()}`);
    },
  });

  useContractRead({
    address: SanctuaryAddress,
    abi: SanctuaryAbi,
    functionName: "maxSupply",
    // args: [address],
    onSuccess(data) {
      setMaxSupply(data.toString());
      //   console.log(data, "data");
    },
    watch: true,
  });
  useContractRead({
    address: SanctuaryAddress,
    abi: SanctuaryAbi,
    functionName: "totalSupply",
    // args: [address],
    onSuccess(data) {
      setTotalSupply(data.toString());
      //   console.log(data, "data");
    },
    watch: true,
  });

  //esl
  const { write: exclusive } = useContractWrite({
    address: contractAddress,
    abi: contractAbi,
    functionName: "mintPublic",
    // value: `${price1 * value * 10 ** 18} `,////use leter
    value: "",
    args: [
      ExclusiveAddress,
      "0x0000a26b00c1F0DF003000390027140000fAa719",
      "0x0000000000000000000000000000000000000000",
      value,
    ],
    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Minting failed ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Minting is Successful ${data.hash.toString()}`);
    },
  });

  useContractRead({
    address: ExclusiveAddress,
    abi: ExclusiveAbi,
    functionName: "maxSupply",
    // args: [address],
    onSuccess(data) {
      setMaxSupply1(data.toString());
      //   console.log(data, "data");
    },
    watch: true,
  });
  useContractRead({
    address: ExclusiveAddress,
    abi: ExclusiveAbi,
    functionName: "totalSupply",
    // args: [address],
    onSuccess(data) {
      setTotalSupply1(data.toString());
      //   console.log(data, "data");
    },
    watch: true,
  });

  return (
    <ContextStaking.Provider
      value={{
        sanctuary,
        exclusive,
        maxSupply,
        totalSupply,
        maxSupply1,
        totalSupply1,
        value,
        setValue,
        price,
        setPrice,
        price1,
        setPrice1,
      }}
    >
      {children}
    </ContextStaking.Provider>
  );
};
