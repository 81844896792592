export const API_URLS1 = {
  StakeMasterList: "getStakeMasterList",
  StbyU: "StbyU",
  getStakedlist: "getStakedlistbySuccess",
  UnstakeNft: "UnstakeNftByuser",
  claimeactivity: "claime-activity",
  getwalletnft: "getwalletnft",
  StakedSuccess: "StakedSuccess",
  StakdFailed: "StakdFailed",
  updateUnstakeFailed: "updateUnstakeFailed",
  updateUnstakeSuccess: "updateUnstakeSuccess",
  VerifySignvalue: "getVerifySignvalue",
  getStorylist: "getStorylist",
  login: "login",
  checkToken: "checkToken",
  Storylist: "getStorylist",
  AvailableMakestory: "checkAvailableMakestory",
  addCreateStory: "addCreateStory",
  createdStorylist: "createdStorylist",
  editStory: "editStory",
  ExitStory: "ExitStory",
  getdailycheckin: "getdailycheckin",
  loginactivity: "login-activity",
  updateclaim: "updateclaim",
  Stakedlist: "getStakedlist",
  nftupdateinstory: "nftupdateinstory",
  khandshakeStory: "checkhandshakeStory",
  requesthanshakesubmit: "requesthanshakesubmit",
  handshakeRequestList: "handshakeRequestList",
  RejectByStorymake: "RequestRejectByStorymake",
  RequestConfirm: "RequestConfirmByStorymake",
  StoryActive: "publicStoryActive",
  StoryInactive: "publicStoryInactive",
  publicListStory: "publicListStory",
  StoryCreate: "publicStoryCreate",
  getstorydatabyid: "getstorydatabyid",
};
