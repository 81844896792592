// src/MintPage.js
import React, { useState, useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { ContextStaking } from "../../Context/ContextStaking";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const MintPage = () => {
  const {
    sanctuary,
    exclusive,
    maxSupply,
    totalSupply,
    maxSupply1,
    totalSupply1,
    value,
    setValue,
    price,
    setPrice,
    price1,
    setPrice1,
  } = useContext(ContextStaking);
  const [checked, setChecked] = React.useState(false);
  const [usdt, setUsdt] = useState();
  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get("type");

  const increment = () => {
    if (value < 30) {
      setValue(value + 1);
    }
  };

  const decrement = () => {
    if (value > 1) {
      setValue(value - 1);
    }
  };

  useEffect(() => {
    const postData = {
      input_token_address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      input_token_amount: `${
        type === "luliu" ? price * 10 ** 18 : price1 * 10 ** 18
      }`,
      output_token_address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      rpc_url: "https://eth.public-rpc.com",
      contract_address: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    };

    fetch(
      "http://awara.apihosting.co.in/regent/version2/get-conversionethtoken",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setUsdt(data?.result?.amount);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  return (
    <div className="flex flex-col  w-full py-5 lg:px-10">
      <div className="flex flex-col">
        <span className="flex justify-between w-full">
          {type === "luliu" && (
            <p className="font-semibold">
              {(totalSupply * 100) / maxSupply} % minted
            </p>
          )}
          {type === "luliu" && (
            <p className="font-semibold">
              {totalSupply}/{maxSupply}
            </p>
          )}
          {/* djkcfhsdhf */}
          {type === "cNp-coin" && (
            <p className="font-semibold">
              {(totalSupply1 * 100) / maxSupply1} % minted
            </p>
          )}
          {type === "cNp-coin" && (
            <p className="font-semibold">
              {totalSupply1}/{maxSupply1}
            </p>
          )}
        </span>
        <span className="my-5 px-10">
          {type === "luliu" && (
            <BorderLinearProgress
              variant="determinate"
              value={(totalSupply * 100) / maxSupply}
            />
          )}
          {/* kkkk */}
          {type === "cNp-coin" && (
            <BorderLinearProgress
              variant="determinate"
              value={(totalSupply1 * 100) / maxSupply1}
            />
          )}
        </span>
      </div>
      <div className="flex flex-col py-8 px-10 shadow-lg my-10 rounded-lg">
        <p className="text-3xl font-bold text-black">Private Stage</p>
        {type === "luliu" && (
          <p className="text-black font-semibold pl-3 my-1">
            {price * value} ETH
          </p>
        )}
        {/* kkkk */}
        {type === "cNp-coin" && (
          <p className="text-black font-semibold pl-3 my-1">
            {price1 * value} ETH
          </p>
        )}

        <p>~${(usdt / 1000000).toFixed(2)}</p>
        <div className="lg:flex w-full gap-5 my-5">
          <span className="flex justify-between gap-2 lg:w-[30%] lg:my0 my-3">
            <p
              onClick={() => decrement()}
              className="bg-gray-300 rounded-lg w-full text-xl font-bold cursor-pointer flex justify-center items-center"
            >
              -
            </p>
            <p className="bg-gray-300 rounded-lg w-full text-xl font-bold cursor-pointer flex justify-center items-center ">
              {value}
            </p>
            <p
              onClick={() => increment()}
              className="bg-gray-300 rounded-lg w-full text-xl font-bold cursor-pointer flex justify-center items-center"
            >
              +
            </p>
          </span>
          <span className="lg:w-[70%] flex justify-center items-center">
            {type === "luliu" && (
              <p
                onClick={() => sanctuary()}
                className="flex  justify-center items-center font-bold text-white bg-black w-full py-2 rounded-lg cursor-pointer"
              >
                Mint
              </p>
            )}
            {type === "cNp-coin" && (
              <p
                onClick={() => exclusive()}
                className="flex  justify-center items-center font-bold text-white bg-black w-full py-2 rounded-lg cursor-pointer"
              >
                Mint
              </p>
            )}
          </span>
        </div>
      </div>

      <div className="flex flex-col py-8 lg:px-10 w-full my-10 ">
        <p className="text-3xl font-bold w-full text-black">Mint schedule</p>
        <div className="flex lg:px-10 px-5 w-full">
          <div className="flex w-full justify-between my-5">
            <span>
              <p className="font-semibold">Private Stage</p>
              <p className="text-gray-500 font-bold">
                2024-06-27T08:10:55.499863
              </p>

              {checked && (
                <p className="font-semibold text-purple-500">
                  {type === "luliu" ? price : price1} ETH
                </p>
              )}
            </span>

            <span className="flex gap-2">
              <p className="font-semibold text-purple-500">Eligible</p>
              {checked ? (
                <ExpandLessIcon
                  onClick={handleChange}
                  className="cursor-pointer"
                />
              ) : (
                <ExpandMoreIcon
                  onClick={handleChange}
                  className="cursor-pointer"
                />
              )}
            </span>
          </div>
        </div>
      </div>
      {/* <Container style={{ marginTop: "50px" }}>
        <Typography variant="h4">Mint Your NFT</Typography>
        <Typography variant="body1">Total Supply: {totalSupply}</Typography>
        <Typography variant="body1">Max Supply: {maxSupply}</Typography>
        <Typography variant="body1">Price per NFT: {price} ETH</Typography>
        <div style={{ margin: "20px 0" }}>
          <TextField
            type="number"
            label="Limit"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            InputProps={{ inputProps: { min: 1, max: 30 } }}
            style={{ marginBottom: "20px" }}
          />
        </div>
        <Button variant="contained" color="secondary" onClick={() => write()}>
          Buy NFT
        </Button>
        {notice && (
          <Typography
            variant="body1"
            color="error"
            style={{ marginTop: "20px" }}
          >
            {notice}
          </Typography>
        )}
      </Container> */}
    </div>
  );
};

export default MintPage;
