import React, { useEffect, useState } from "react";

import logo from "../../Images/mowgli-sanctuary.png";
import { useNavigate } from "react-router-dom";
import Sign from "../../Alldilog/Sign";
import CreateButoon from "../../Alldilog/ConnectButton";
import { useAccount } from "wagmi";

export default function Header() {
  const navigate = useNavigate();
  const { address } = useAccount();
  const type = localStorage.getItem("type");

  return (
    <>
      <div className="flex  bg-black bg-opacity-15 fixed z-50 w-full py-2 items-center">
        <div className="flex w-full justify-between items-center px-10">
          <div className="lg:w-[50%]  ">
            <img
              onClick={() => navigate("/")}
              src={logo}
              alt=""
              className="lg:h-15 h-16 lg:w-[52] cursor-pointer"
            />
          </div>

          <div className="flex items-center justify-center px-3  gap-20">
            <p
              onClick={() => navigate(`/?type=${type}`)}
              className="text-2x font-bold text-black cursor-pointer lg:block hidden"
            >
              Home
            </p>
            <p
              onClick={() => navigate("/minted-nft")}
              className="text-2x font-bold text-black cursor-pointer lg:block hidden"
            >
              Minted nft
            </p>
            <p
              // onClick={() => navigate("/dashboard")}
              className="text-2x font-bold text-black cursor-pointer lg:block hidden"
            >
              <CreateButoon />
              {address ? "" : <Sign />}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
