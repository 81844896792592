import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axiosInstance1 from "../../Config1/axios";

export default function MintedNft() {
  const [nft, setNft] = useState();
  const type = localStorage.getItem("type");

  useEffect(() => {
    const postData = {
      input_token_address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      input_token_amount: "1000000000000000000",
      output_token_address: "0x55d398326f99059fF775485246999027B3197955",
    };

    fetch("https://jsonplaceholder.typicode.com/photos", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const getData = () => {
    axiosInstance1
      .get(`collection/${type}/nfts`, {})
      .then((res) => {
        setNft(res.data.nfts);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="flex flex-col ">
      <div className="grid grid-cols-4 gap-5 mt-20 py-5 px-32">
        {nft?.map((i) => {
          return (
            <Card>
              <CardMedia
                sx={{ height: 200, objectFit: "cover" }}
                image={i.image_url}
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {i.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {i.description}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </div>
    </div>
  );
}
